import React, { lazy, Suspense, useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { Row, Col, Skeleton, message, Modal, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';

function UsersPage() {
  //   const { searchData } = useSelector((state) => {
  //     return {
  //       searchData: state.headerSearchData,
  //     };
  //   });

  const [employees, setEmployees] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token = Cookies.get('access_token');
        const response = await axios.get('https://backend.bizdin.ai/employees', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const formattedEmployees = response.data.map((employee) => ({
          id: employee.id,
          fullName: employee.full_name,
          dateOfBirth: employee.date_birth,
          iin: employee.iin,
          document: employee.document,
        }));
        setEmployees(formattedEmployees);
        setFilteredData(formattedEmployees);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, []);

  const handleSearch = (searchText) => {
    const data = employees.filter((item) => item.fullName.toUpperCase().startsWith(searchText.toUpperCase()));
    setFilteredData(data);
  };

  const handleDelete = async (id) => {
    try {
      const token = Cookies.get('access_token');
      await axios.delete(`https://backend.bizdin.ai/employees/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('Сотрудник успешно удалён');
      setEmployees((prev) => prev.filter((employee) => employee.id !== id));
      setFilteredData((prev) => prev.filter((employee) => employee.id !== id));
    } catch (error) {
      console.error('Error deleting employee:', error);
      message.error('Не удалось удалить сотрудника');
    }
  };

  const handleEdit = (employee) => {
    setCurrentEmployee(employee);
    form.setFieldsValue({
      fullName: employee.fullName,
      dateOfBirth: employee.dateOfBirth,
      iin: employee.iin,
    });
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const token = Cookies.get('access_token');
      const updatedData = await form.validateFields();
      await axios.put(
        `https://backend.bizdin.ai/employees/${currentEmployee.id}`,
        {
          full_name: updatedData.fullName,
          date_birth: updatedData.dateOfBirth,
          iin: updatedData.iin,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      message.success('Данные сотрудника успешно обновлены');
      // Обновите состояние
      setEmployees((prev) => prev.map((emp) => (emp.id === currentEmployee.id ? { ...emp, ...updatedData } : emp)));
      setFilteredData((prev) => prev.map((emp) => (emp.id === currentEmployee.id ? { ...emp, ...updatedData } : emp)));
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error updating employee:', error);
      message.error('Не удалось обновить данные сотрудника');
    }
  };

  const actions = (employee) => (
    <>
      <Link to="#">
        <UilEye />
        <span>View</span>
      </Link>
      <Link to="#" onClick={() => handleEdit(employee)}>
        <UilEdit />
        <span>Edit</span>
      </Link>
      <Link
        to="#"
        onClick={() => {
          Modal.confirm({
            title: 'Вы уверены, что хотите удалить этого сотрудника?',
            onOk: () => handleDelete(employee.id),
          });
        }}
      >
        <UilTrashAlt />
        <span>Delete</span>
      </Link>
    </>
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          className="ninjadash-page-header-main"
          backIcon={false}
          title="Team Members"
          subTitle={
            <>
              <span className="title-counter">{employees.length} Users</span>
              <AutoComplete
                onSearch={handleSearch}
                dataSource={filteredData}
                width="100%"
                placeholder="Search by Name"
                patterns
              />
            </>
          }
          buttons={[
            <Link to="/admin/pages/wizards" className="btn-add_new">
              <UilPlus /> Add New Member
            </Link>,
          ]}
        />
      </CardToolbox>
      <Main>
        <Row gutter={25}>
          {Array.isArray(filteredData) &&
            filteredData.map((user) => {
              const EmployeesCard = lazy(() => import('./overview/EmployeesCard'));
              return (
                <Col key={user.id} xxl={6} lg={8} sm={12} xs={24}>
                  <Suspense
                    fallback={
                      <Cards headless>
                        <Skeleton avatar active />
                      </Cards>
                    }
                  >
                    <EmployeesCard
                      actions={actions(user)}
                      user={{
                        name: user.fullName,
                        dateOfBirth: user.dateOfBirth,
                        iin: user.iin,
                        document: user.document,
                      }}
                    />
                  </Suspense>
                </Col>
              );
            })}
        </Row>
      </Main>

      <Modal
        title="Редактировать Сотрудника"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="ФИО" name="fullName" rules={[{ required: true, message: 'Пожалуйста, введите ФИО!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Дата рождения"
            name="dateOfBirth"
            rules={[{ required: true, message: 'Пожалуйста, введите дату рождения!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="ИИН" name="iin" rules={[{ required: true, message: 'Пожалуйста, введите ИИН!' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UsersPage;
