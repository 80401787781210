import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Radio, Button, Input, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import WebcamComponent from './WebcamComponent';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import './VideoTestPage.css';

function VideoTestPage() {
  const [mode, setMode] = useState(1);
  const [text, setText] = useState(`1. Законные нарушения:
    - Драка
    - Отображение нецензурных символов (средний палец, большой палец вниз)
  
  2. Статистика клиентов:
    - Более 4 человек на кассе
    - Неопрятный стол.`);
  const [textarea, setTextarea] = useState('What is happening?');
  const [email, setEmail] = useState('');
  const [lang, setLanguage] = useState('English');
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [responseText, setResponseText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const onRadioChange = (e) => {
    setMode(e.target.value);

    setIsCameraOn(false);

    if (e.target.value === 2) {
      setShowResponse(false);
      setResponseText('');
    }
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleTextareaChange = (e) => {
    setTextarea(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLanguageChange = (value) => {
    setLanguage(value);
  };

  const sendScreenshot = async (imageBase64) => {
    setIsLoading(true);
    setIsSending(true);

    const url =
      mode === 1 ? 'https://backend.bizdin.ai/api/core/mvp/describe/' : 'https://backend.bizdin.ai/api/core/mvp/search';

    const payload =
      mode === 1
        ? { image: imageBase64, question: textarea, lang }
        : { image: imageBase64, rules: text, receiver: email, lang };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data && data.response) {
        setResponseText(data.response);
        setShowResponse(true);

        setTimeout(() => {
          setShowResponse(false);
        }, 5000);
      } else {
        setResponseText('Ответ от сервера пустой или содержит null значения');
        setShowResponse(true);

        setTimeout(() => {
          setShowResponse(false);
        }, 5000);
      }
    } catch (error) {
      setResponseText('Ошибка при отправке запроса');
      setShowResponse(true);

      setTimeout(() => {
        setShowResponse(false);
      }, 5000);
    } finally {
      setIsLoading(false);
      setIsSending(false);
    }
  };

  useEffect(() => {
    let interval;
    if (isSending && isCameraOn) {
      interval = setInterval(() => {
        const videoElement = document.querySelector('video');
        if (videoElement) {
          const canvas = document.createElement('canvas');
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const context = canvas.getContext('2d');
          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          const screenshot = canvas.toDataURL('image/jpeg');

          if (screenshot) {
            const base64Data = screenshot.split(',')[1];
            sendScreenshot(base64Data);
          }
        }
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isSending, isCameraOn, textarea, text, email, lang]);

  useEffect(() => {
    if (!isCameraOn) {
      setResponseText('');
      setShowResponse(false);
    }
  }, [isCameraOn]);

  const startSending = () => {
    setIsSending(true);
  };

  const PageRoutes = [
    {
      path: 'index',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '',
      breadcrumbName: 'Video Test Page',
    },
  ];

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Video Test" routes={PageRoutes} />
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <h1>Please, select options for MVP</h1>

              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                <p>Language</p>
                <Select defaultValue="English" style={{ width: '30%' }} onChange={handleLanguageChange}>
                  <Select.Option value="English">English</Select.Option>
                  <Select.Option value="Russian">Russian</Select.Option>
                </Select>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '20px' }}>
                <p>Mode</p>
                <Radio.Group onChange={onRadioChange} value={mode} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Radio value={1}>
                    Describe <br /> <span>You can ask questions</span>
                  </Radio>
                  <Radio style={{ marginTop: '20px' }} value={2}>
                    Search <br /> <span>Search for violations</span>
                  </Radio>
                </Radio.Group>
              </div>

              <WebcamComponent isCameraOn={isCameraOn} setIsCameraOn={setIsCameraOn} />

              {mode === 1 && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '20px' }}>
                  <p>Question</p>
                  <TextArea value={textarea} onChange={handleTextareaChange} rows={4} />
                  <Button style={{ marginTop: '20px' }} type="dashed" onClick={startSending}>
                    Accept
                  </Button>
                </div>
              )}

              {mode === 2 && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '20px' }}>
                  <p>Enter email</p>
                  <Input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="default@gmail.com"
                    required
                  />

                  <p style={{ marginTop: '50px' }}>Your rules</p>
                  <TextArea value={text} onChange={handleTextChange} rows={10} />
                  <Button style={{ marginTop: '20px' }} type="dashed" onClick={startSending}>
                    Accept
                  </Button>
                </div>
              )}

              {mode === 1 && showResponse && isLoading && (
                <div className="loading-container">
                  <Spin size="large" />
                  <p>Loading...</p>
                </div>
              )}

              {mode === 1 && isCameraOn && !isLoading && showResponse && (
                <h1 className={`response-text ${showResponse ? 'show animate-text' : 'hide'}`}>{responseText}</h1>
              )}
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default VideoTestPage;
